import React, { Fragment } from 'react'
import { Accordion } from 'react-bootstrap'
import Card from './Card'

const Faq = () => {
  return (
    <div className='container'>
      <div className='alert alert-primary shadow-lg rounded custom-margin'>
        {' '}
        Please go to the{' '}
        <a href='http://www.texasfailuretoappear.com/'>
          Texas Failure to Appear Website
        </a>{' '}
        to look up any outstanding tickets you may have.
      </div>
      <Accordion defaultActiveKey='0'>
        <Card
          eventKey={0}
          header={
            'Where can I look up violations that are causing my license to be on hold?'
          }
          cardHeader={[
            `To look up outstanding cases try `,
            <a href='http://texasfailuretoappear.com/search.php'>
              Texas Failure to Appear
            </a>,
            ` website.`
          ]}
        />
        <Card
          eventKey={1}
          header={'How do I resolve violations on my record?'}
          cardHeader={[
            `You need to contact the courts directly in order to resolve open violations. To look up outstanding cases try `,
            <a href='http://texasfailuretoappear.com/search.php'>
              {' '}
              Texas Failure to Appear
            </a>,
            ` website.`
          ]}
        />
        <Card
          eventKey={2}
          header={`How long does it take to lift a hold on a license?`}
          cardHeader={
            <Fragment>
              Call the court to find out when they will send the clearence of
              your violations. If we recieve the clearence from the court by 7pm
              you will be cleared the next business day. To look up outstanding
              cases try{' '}
              <a href='http://texasfailuretoappear.com/search.php'>
                {' '}
                Texas Failure to Appear
              </a>{' '}
              website.
            </Fragment>
          }
        />
        <Card
          eventKey={3}
          header={`Do I have any warrants?`}
          cardHeader={[
            `There is no centralized public database for warrants. You will need to contact the court that you think has the warrant information on you. To look up outstanding cases try `,
            <a href='http://texasfailuretoappear.com/search.php'>
              {' '}
              Texas Failure to Appear
            </a>,
            ` website.`
          ]}
        />
        <Card
          eventKey={4}
          header={`Are these warrants?`}
          cardHeader={[
            `Unfortunately we do not carry any warrant information. You will need to contact the court that you have the warrant with. To look up outstanding cases try `,
            <a href='http://texasfailuretoappear.com/search.php'>
              {' '}
              Texas Failure to Appear
            </a>,
            ` website.`
          ]}
        />
        <Card
          eventKey={5}
          header={`Is this the surcharge department?`}
          cardHeader={[
            `No. You will need to contact the `,
            <a href='https://www.txsurchargeonline.com'>
              Texas Department of Public Safety Surcharge
            </a>,
            ` department at 1-800-688-6882.`
          ]}
        />
        <Card
          eventKey={6}
          header={`I don't have a Texas Drivers License`}
          cardHeader={`We only hold information on Texas drivers license. Non residents will need to contact the Texas Department of Public Safety at 512-424-2600 about non resident compact information.`}
        />
        <Card
          eventKey={7}
          header={`I did jail time, doesn't that take care of all my fines?`}
          cardHeader={[
            `You will need to contact the courts you have open offenses with to find out the answer to that. To look up outstanding cases try `,
            <a href='http://texasfailuretoappear.com/search.php'>
              {' '}
              Texas Failure to Appear
            </a>,
            ` website.`
          ]}
        />
      </Accordion>
    </div>
  )
}

export default Faq

import React from 'react'
import { Container } from 'react-bootstrap'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import styled from 'styled-components'

import Routes from './Routes/Routes'

function App() {
  return (
    <AppContainer>
      <Header />
      <Routes />
      <Footer />
    </AppContainer>
  )
}

export default App

const AppContainer = styled(Container)`
  height: 100vh;
`

import React from 'react'
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap'
import Logo from '../../resources/logo.png'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import Pdf from '../../resources/webftamanual.pdf'
import Info from '../../resources/PSINFO.DOC'

const Header = () => {
  return (
    <div>
      <Image src={Logo} fluid />
      <Navbar>
        <Link to='/' exact>
          Home
        </Link>
        <Nav className='mr-auto'>
          <NavDropdown title='Administrative'>
            <List>
              <Link to='/program'>Failure to Appear Program</Link>
              <Link to='/section706'>
                Section 706 of the Texas Transportation Code
              </Link>
              {/* <Link>Newsletter</Link>
                <Link>WebFTA Training</Link> */}
            </List>
          </NavDropdown>
          <NavDropdown title='Support/Help'>
            <List>
              <Link to='/faq'>FAQ Page</Link>
              <Link to='/reqs'>FTA Requirments</Link>
              <Link to='/codes'>DPS Codes</Link>
              <Link to='/contact'>Contact Support</Link>
            </List>
          </NavDropdown>
          <NavDropdown title='Downloads'>
            <List>
              <OutsideLink href={Pdf} target='_blank'>
                WebFTA Manual
              </OutsideLink>
              <OutsideLink href={Info} target='_blank'>
                Information Sheet
              </OutsideLink>
            </List>
          </NavDropdown>
          <NavDropdown title='Links'>
            <List>
              <OutsideLink href='https://www.dps.texas.gov/driverlicense/'>
                Texas DPS Drivers License
              </OutsideLink>
              <OutsideLink href='http://www.tmcec.com'>
                Texas Municipal Courts Education Center
              </OutsideLink>
              <OutsideLink href='http://www.tml.org'>
                Texas Municipal League
              </OutsideLink>
              <OutsideLink href='http://www.county.org'>
                Texas Association of Counties
              </OutsideLink>
              <OutsideLink href='http://www.tjctc.org'>
                Texas Justice Court Training Center
              </OutsideLink>
              <OutsideLink href='http://www.txmca.com'>
                Texas Municipal Courts Association
              </OutsideLink>
            </List>
          </NavDropdown>
        </Nav>
      </Navbar>
    </div>
  )
}

export default Header

const Link = styled(NavLink)`
  text-decoration: none;
  color: black;
  padding: 8px;
  &: hover {
    background-color: #f1f5fa;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`

const OutsideLink = styled.a`
  text-decoration: none;
  color: black;
  padding: 8px;
  &: hover {
    background-color: #f1f5fa;
  }
`

import React from 'react'

const Contact = () => {
  return (
    <div className='p-2 flex-fill custom-margin'>
      <div className='display-4 custom-margin' align='center'>
        {' '}
        OmniBase Contacts
      </div>
      <div className='alert alert-primary shadow rounded'>
        <h3 align='center'>
          To look up outstanding offenses online, please go to the <br />{' '}
          <a href='http://www.texasfailuretoappear.com'>
            Texas Failure To Appear Website
          </a>
        </h3>
      </div>
      <div className='custom-margin' align='center'>
        <h3> - OR - </h3>
      </div>
      <div align='center'>
        <h3>To call about outstanding offense information: </h3>
        <h3>
          Phone Number: (800) 686-0570
          <br />
        </h3>
      </div>
    </div>
  )
}

export default Contact

import React from 'react'
import styled from 'styled-components'

const CodeList = ({ list }) => {
  return (
    <CodeListContainer key={list.OffenseCode}>
      <OffenseCode>{list.OffenseCode}</OffenseCode>
      <OffenseName>{list.OffenseName}</OffenseName>
    </CodeListContainer>
  )
}

export default CodeList

const CodeListContainer = styled.div`
  font-family: Arial, san-serif; xz
  margin: 12px;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-width: 150px;
`

const OffenseName = styled.h5`
  font-family: Arial, sans-serif;
  font-size: 14px;
`

const OffenseCode = styled.h5`
  font-family: Arial, sans-serif;
  font-size: 16px;
`

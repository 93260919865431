import React from 'react'
import { Card, Accordion } from 'react-bootstrap'

const Faqcard = ({ header, cardHeader, eventKey }) => {
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        {header}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>{cardHeader}</Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

export default Faqcard

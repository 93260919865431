import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from '../components/Home'
import Faq from '../components/Faq/Faq'
import Contact from '../components/Contact'
import FtaDescription from '../components/FtaDescription'
import SectionSeven from '../components/SectionSeven'
import Reqs from '../components/FtaReqs'
import Codes from '../components/DpsCodes/DpsCodes'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/faq' component={Faq} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/program' component={FtaDescription} />
      <Route exact path='/section706' component={SectionSeven} />
      <Route exact path='/reqs' component={Reqs} />
      <Route exact path='/codes' component={Codes} />
    </Switch>
  )
}

export default Routes

import React from 'react'
import { Navbar } from 'react-bootstrap'

export default function Footer() {
  const date = new Date()
  return (
    <Navbar sticky='bottom'>
      <span>&copy; 2016 - {date.getFullYear()} OmniBase Services</span>
    </Navbar>
  )
}

import React from 'react'
import { Container } from 'react-bootstrap'

const FtaDescription = () => {
  return (
    <Container>
      <div className='d-flex flex-wrap'>
        <h2 className='display-4' align='center'>
          Failure To Appear (FTA) Program
        </h2>
        <p>
          As a result of nonpayment of fines associated with certain violations,
          political subdivisions and the State of Texas have encountered a
          significant loss of revenue. The TDPS offers a solution to serve the
          political subdivisions by denying the renewal of a driver license for
          failure to appear or failure to pay or satisfy a judgment ordered by a
          court. It is estimated that between 95 and 98 percent of the FTA
          offenders will comply with the political subdivisions that contract
          with the Department. The intent of the Failure to Appear Program is
          directed toward a system that requires the violator to appear before
          the originating court for a final disposition. This pamphlet
          identifies the sequence of events designed to bring both traffic and
          non-traffic violators to justice. During the 74th Legislative Session,
          Senate Bill 1504, Texas Transportation Code, Chapter 706 (formerly
          Vernon's Civil Statute, 6687d), authorized the Department to contract
          with political subdivisions to deny the renewal of an individual's
          driver license for failure to appear on certain traffic violations on
          or after September 1, 1995. In the 76th Legislative Session, House
          Bill 2802 amended the Texas Transportation Code, Chapter 706, to
          include all offenses for which the violator fails to appear, that are
          within the jurisdiction of the court. The main provisions of the bill
          are as follows:
        </p>
        <ul>
          <li>
            Authorizes the Department to contract with a private vendor to
            implement the system.
          </li>
          <li>
            Requires the political subdivision to compensate the private vendor
            for service delivered under the provisions of this bill (prohibits
            the use of state funds to compensate a private vendor).
          </li>
          <li>
            Provides for an administrative fee of $30.00 for each offense.
          </li>
          <li>
            Establishes immunity from suit and damages for the state and
            political subdivisions.
          </li>
          <li>
            Authorizes the Department to adopt rules to implement the provisions
            of the bill.
          </li>
          <li>
            Requires the peace officer to give notice of the sanction at the
            time a citation is written.
          </li>
          <li>
            Applies to offenses that occur on or after September 1, 1999.{' '}
          </li>
        </ul>
        <p>
          During the 77th Legislative Session, Senate Bill 1371 further amended
          the Texas Transportation Code to include offenses for which an
          individual fails to pay or satisfy the judgment of a court order. This
          amendment expands the scope of the program and gives the court greater
          flexibility. These provisions were effective September 1, 2001. During
          the 78th Legislative Session, Senate Bill 782 further amended the
          Texas Transportation Code to include any offense that a court has
          jurisdiction of under Chapter 4, Code of Criminal Procedure. This
          provision was effective June 18, 2003. Chapter 706 of the Texas
          Transportation Code applies to all offenses that fall within the
          municipal or county court's jurisdiction, including both{' '}
          <em>
            <strong>traffic</strong>
          </em>{' '}
          and{' '}
          <em>
            <strong>non-traffic violations</strong>
          </em>
          .{' '}
          <em>
            <strong>Traffic violations</strong>
          </em>{' '}
          regulate a driver's conduct or condition while operating a motor
          vehicle, or the condition of a motor vehicle while it is being
          operated on a street, road or highway.{' '}
          <em>
            <strong>Non-traffic violations</strong>
          </em>{' '}
          are those usually found in the Penal Code of Texas and associated
          state laws and city ordinance.{' '}
        </p>
        <h3 align='center'>
          {' '}
          Required Warning on Citations for Traffic Law Violations:
        </h3>
        <p>
          A peace officer authorized to issue citations within the jurisdiction
          of the local political subdivision shall issue a written warning to
          each person to whom the officer issues a citation for a traffic law
          violation. This warning shall be provided in addition to any other
          warnings required by law. The warning must state in substance that if
          the person fails to appear in court for the prosecution of the
          offense, or fails to pay or satisfy a judgment ordering the payment of
          a fine and cost in the manner ordered by the court, the person may be
          denied renewal of the person's driver license. The written warning may
          be printed on the citation or on a separate document.
        </p>
        <h3 align='center'> Warrants:</h3>
        <p>
          It is currently estimated that as few as 25 percent of warrants issued
          are brought to final disposition. This means that over one and three
          quarter million offenders are ultimately not brought to justice. The
          FTA Program does not require a warrant to be issued in response to a
          person's failure to appear. Whether a political subdivision issues a
          warrant or not is irrelevant to an offense being accepted into the FTA
          system. It is the opinion of the political subdivision whether or not
          to continue issuing warrants. However, the warrant fee can only be
          enforced if a warrant is issued. Some courts have decided to issue a
          warrant in addition to entering an individual into the FTA system,
          while others have stated they will no longer issue warrants.
        </p>
        <p>&nbsp;</p>
      </div>
    </Container>
  )
}

export default FtaDescription

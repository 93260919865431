import React, { useState } from 'react'
import styled from 'styled-components'
import CodeList from './CodeList'

import codesArray from '../../resources/texasoffensecodes.json'

const DpsCodes = () => {
  const [data] = useState(codesArray)
  const [search, setSearch] = useState('')

  const handleSearch = event => {
    setSearch(event.target.value)
  }

  const results = !search
    ? []
    : data.filter(name =>
        name.OffenseName.toUpperCase().includes(search.toUpperCase())
      )

  return (
    <Container>
      <InputContainer>
        <Input
          type='text'
          placeholder='Search DPS codes'
          value={search}
          onChange={handleSearch}
        ></Input>
      </InputContainer>
      <ListContainer>
        {results.map(code => {
          return <CodeList list={code} />
        })}
      </ListContainer>
    </Container>
  )
}

export default DpsCodes

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Input = styled.input`
  width: 50%;
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Container = styled.div`
  margin: auto;
`

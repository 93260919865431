import React from 'react'

const FtaReqs = () => {
  return (
    <div className='container'>
      <div className='d-flex flex-wrap'>
        <div className='display-4 custom-margin'>
          Failure To Appear (FTA) Program
        </div>
        <p className='bd-lead'>
          Participation in the FTA program requires the participating
          jurisdiction to have an up to date web browser (ie. Chrome, Firefox,
          Microsoft Edge).
        </p>
        <p className='bd-lead'>
          The Vendor will provide the software necessary for the jurisdiction to
          participate in the FTA Program, at no charge.
        </p>
        <p className='bd-lead'>
          If the jurisdiction uses a third-party court software vendor, (i.e.,
          OCA, CSI, HCS, etc.) the court software vendor's application will
          manage the database, do the reporting of offenses, make corrections on
          denied/rejected records and export them to RES. Accordingly, the
          jurisdiction may be able to avoid the double entry of violations and
          only use RES for the transmission of the offenses to the Vendor.{' '}
        </p>
        <p className='bd-lead'>
          The jurisdiction will use its computer to upload data through a web
          browser or via FTP to the Vendor's server. Each business day, the
          Vendor will download the data received from jurisdictions and store
          the data. The data is then delivered to DPS daily where it is
          processed against driver license records. After processing, DPS
          provides confirmation or a rejected status of the records that were
          transmitted the previous day. The Vendor retrieves the daily processes
          and confirms and rejections into the Vendor database server for the
          jurisdiction to download the next time the jurisdiction transmits.{' '}
        </p>
        <p className='bd-lead'>
          RES will report all records rejected by DPS. The jurisdiction may
          correct and retransmit the records that were rejected.{' '}
        </p>
        <p className='bd-lead'>
          An alternative means of transmission may be available to large
          jurisdictions that have a mainframe or server and are expecting to
          transmit a large volume of cases each day. These systems may either
          use the Web system or transmit records directly to the Vendor server
          by FTPS. This alternative would probably require special programming
          by the jurisdiction and would be at the jurisdiction's own expense.{' '}
        </p>
        <p className='bd-lead'>
          This summary is intended to provide a general description of the
          technical application of the Failure to Appear Program. For more
          specific information on the technical systems, jurisdictions may
          contact the vendor, OmniBase Services of Texas, LP, at (512) 346-6511.
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  )
}

export default FtaReqs

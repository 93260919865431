import React from 'react'

import { Accordion, Card } from 'react-bootstrap'

const SectionSeven = () => {
  return (
    <div className='container'>
      <div className='display-4 custom-margin' align='center'>
        Chapter 706 of the Transportation Code
      </div>
      <Accordion defaultActiveKey='0'>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='0'>
            Section 706.001 - Definitions
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              <h3>Definitions</h3>
              <ul>
                <li>
                  "Complaint" means a notice of an offense as described by
                  Article 27.14(d) or 45.019, Code of Criminal Procedure.{' '}
                </li>
                <li>"Department" means the Department of Public Safety. </li>
                <li>
                  "Driver's license" has the meaning assigned by Section
                  521.001.{' '}
                </li>
                <li>
                  "Highway or street" has the meaning assigned by Section
                  541.302.{' '}
                </li>
                <li>
                  "Motor vehicle" has the meaning assigned by Section 541.201.{' '}
                </li>
                <li>"Operator" has the meaning assigned by Section 541.001.</li>
                <li>
                  {' '}
                  "Political subdivision" means a municipality or county.
                </li>
                <li>
                  {' '}
                  "Public place" has the meaning assigned by Section 1.07, Penal
                  Code.
                </li>
                <li>
                  {' '}
                  "Traffic law" means a statute or ordinance, a violation of
                  which is a misdemeanor punishable by a fine in an amount not
                  to exceed $1,000, that:
                  <ol>
                    <li>
                      regulates an operator's conduct or condition while
                      operating a motor vehicle on a highway or street or in a
                      public place;{' '}
                    </li>
                    <li>
                      regulates the condition of a motor vehicle while it is
                      being operated on a highway or street; relates to the
                      driver's license status of an operator while operating a
                      motor vehicle on a highway or street;{' '}
                    </li>
                    <li>
                      or relates to the registration status of a motor vehicle
                      while it is being operated on a highway or street.
                    </li>
                  </ol>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='1'>
            Section 706.002 - Contract With Department
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              <h4>Contract With Department</h4>
              <ol>
                <li>
                  A political subdivision may contract with the department to
                  provide information necessary for the department to deny
                  renewal of the driver's license of a person who fails to
                  appear for a complaint or citation or fails to pay or satisfy
                  a judgement ordering payment of a fine and cost in the manner
                  ordered by the court in a matter involving any offense that a
                  court has jurisdiction of under Chapter 4, Code of Criminal
                  Procedure.{' '}
                </li>
                <li>
                  A contract under this section:
                  <ol>
                    <li>
                      must be made in accordance with Chapter 791, Government
                      Code; and{' '}
                    </li>
                    <li>
                      is subject to the ability of the parties to provide or pay
                      for the services required under the contract.
                    </li>
                  </ol>
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='2'>
            Section 706.003 - Warning; Citation
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='2'>
            <Card.Body>
              <h4>Warning; Citation</h4>
              <ol type='A'>
                <li>
                  If a political subdivision has contracted with the department,
                  a peace officer authorized to issue a citation in the
                  jurisdiction of the political subdivision shall issue a
                  written warning to each person to whom the officer issues a
                  citation for a violation of a traffic law in the jurisdiction
                  of the political subdivision.{' '}
                </li>
                <li>
                  The warning under Subsection a:
                  <ol>
                    <li>
                      is in addition to any other warning required by law;
                    </li>
                    <li>
                      must state in substance that if the person fails to appear
                      in court as provided by law for the prosecution of the
                      offense or if the person fails to pay or satisfy a
                      judgement ordering the payment of a fine and cost in the
                      manner ordered by the court, the person may be denied
                      renewal of the person's driver's license; and{' '}
                    </li>
                    <li>
                      may be printed on the same instrument as the citation.
                    </li>
                  </ol>
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='3'>
            Section 706.004 - Denial of Renewal of Driver's License
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='3'>
            <Card.Body>
              <h4>Denial of Renewal of Driver's License </h4>
              <ol type='A'>
                <li>
                  If a political subdivision has contracted with the department,
                  on receiving the necessary information from the political
                  subdivision the department may deny renewal of the person's
                  driver's license for failure to appear based on a complaint or
                  citation or failure to pay or satisfy a judgement ordering the
                  payment of a fine and cost in the manner ordered by the court
                  in a matter involving an offense described by Section 706.002
                  a.{' '}
                </li>
                <li>
                  The information must include:
                  <ol>
                    <li>
                      the name, date of birth, and driver's license number of
                      the person;{' '}
                    </li>
                    <li>the nature and date of the alleged violation; </li>
                    <li>
                      a statement that the person failed to appear as required
                      by law or failed to satisfy a judgement ordering the
                      payment of a fine and cost in the manner ordered by the
                      court in a matter involving an offense described by
                      Section 706.002 (a); and{' '}
                    </li>
                    <li>any other information required by the department. </li>
                  </ol>
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='4'>
            Section 706.005 - Clearance Notice to Department
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='4'>
            <Card.Body>
              <h4>Clearance Notice to Department</h4>
              <ol type='A'>
                <li>
                  A political subdivision shall notify the department that there
                  is no cause to continue to deny renewal of a person's driver's
                  license based on the person's previous failure to appear or
                  failure to pay or satisfy a judgement ordering the payment of
                  a fine and cost in the manner ordered by the court in a matter
                  involving an offense described by Section 706.002(a), on
                  payment of a fee as provided by Section 706.006 and:
                  <ol>
                    <li>
                      the perfection of an appeal of the case for which the
                      warrant of arrest was issued or judgement arose;
                    </li>
                    <li>
                      the dismissal of the charge for which the warrant of
                      arrest was issued or judgement arose;
                    </li>
                    <li>
                      {' '}
                      the posting of bond or the giving of other security to
                      reinstate the charge for which the warrant was issued;
                    </li>
                    <li>
                      the payment or discharge of the fine and cost owed on an
                      outstanding judgement of the court; or
                    </li>
                    <li>
                      other suitable arrangements to pay the fine and cost
                      within the court's discretion
                    </li>
                  </ol>
                  <li>
                    The department may not continue to deny the renewal of the
                    person's drivers license under this chapter after the
                    department receives notice:
                    <ol>
                      <li>under Subsection a;</li>
                      <li>
                        that the person was acquitted of the charge on which the
                        person failed to appear; or
                      </li>
                      <li>
                        from the political subdivision that the failure to
                        appear report or court order to pay a fine or cost
                        relating to the person:
                        <ol type='a'>
                          <li>was sent to the department in error; or</li>
                          <li>
                            has been destroyed in accordance with the political
                            subdivision's records retention policy.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='5'>
            Section 706.006 - Payment of Administrative Fee
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='5'>
            <Card.Body>
              <h4>Payment of Administrative Fee</h4>
              <ol type='A'>
                <li>
                  A person who fails to appear for a complaint or citation for
                  an offense described by Section 706.002 (a) shall be required
                  to pay an administrative fee of $30 for each violation for
                  which the person failed to appear, unless the person is
                  acquitted of the charges for which the person failed to
                  appear. The person shall pay the fee when:
                  <ol>
                    <li>
                      the court enters judgement on the underlying offense
                      reported to the department;
                    </li>
                    <li> the underlying offense is dismissed; or</li>
                    <li>
                      bond or other security is posted to reinstate the charge
                      for which the warrant was issued.
                    </li>
                  </ol>
                </li>
                <li>
                  {' '}
                  A person who fails to pay or satisfy a judgement ordering the
                  payment of a fine and cost in the manner the court orders
                  shall be required to pay an administrative fee of $30.
                </li>
                <li>
                  The department may deny renewal of the drivers license of a
                  person who does not pay a fee due under this section until the
                  fee is paid. The fee required by this section is in addition
                  to any other fee required by law.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='6'>
            Section 706.007 - Records Relating to Fees; Disposition of Fees
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='6'>
            <Card.Body>
              <h4>Records Relating to Fees; Disposition of Fees</h4>
              <ol type='A'>
                <li>
                  An officer collecting a fee under Section 706.006 shall
                  <ol>
                    <li>keep separate records of the money; and</li>
                    <li>
                      deposit the money in the appropriate municipal or county
                      treasury.
                    </li>
                  </ol>
                </li>
                <li>
                  The custodian of the municipal or county treasury may:
                  <ol>
                    <li>
                      deposit each fee collected under Section 706.006 in an
                      interest-bearing account; and
                    </li>
                    <li>
                      retain for the municipality or county the interest earned
                      on money in the account.
                    </li>
                  </ol>
                </li>
                <li>
                  The custodian shall keep records of money received and
                  disbursed under this section and shall provide an annual
                  report, in the form approved by the comptroller, of all money
                  received and disbursed under this section to:
                  <ol>
                    <li>the comptroller;</li>
                    <li>the department; and</li>
                    <li>another entity as provided by interlocal contract.</li>
                  </ol>
                </li>
                <li>
                  Of each fee collected under Section 706.006, the custodian of
                  a municipal or county treasury shall:
                  <ol>
                    <li>
                      send $20 to the comptroller on or before the last day of
                      each calendar quarter; and
                    </li>
                    <li>
                      deposit the remainder to the credit of the general fund of
                      the municipality or county.
                    </li>
                  </ol>
                </li>
                <li>
                  {' '}
                  Of each $20 received by the comptroller, the comptroller shall
                  deposit $10 to the credit of the department to implement this
                  chapter.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='7'>
            Section 706.008 - Contract With Private Vendor; Compensation
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='7'>
            <Card.Body>
              <h4>Contract With Private Vendor; Compensation</h4>
              <ol type='A'>
                <li>
                  The department may contract with a private vendor to implement
                  this chapter.
                </li>
                <li>
                  The vendor performing the contract may be compensated by each
                  political subdivision that has contracted with the department.
                </li>
                <li>
                  Except for an action based on a citation issued by a peace
                  officer employed by the department, the vendor may not be
                  compensated with state money.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='8'>
            Section 706.009 - Vendor to Provide Customer Support Services
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='8'>
            <Card.Body>
              <h4>Vendor to Provide Customer Support Services</h4>
              <ol type='A'>
                <li>
                  A vendor must establish and maintain customer support services
                  as directed by the department, including a toll-free telephone
                  service line to answer and resolve questions from persons who
                  are denied renewal of a driver's license under this chapter.
                </li>
                <li>
                  The vendor shall comply with terms, policies, and rules
                  adopted by the department to administer this chapter.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='9'>
            Section 706.010 - Use of Information Collected by Vendor
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='9'>
            <Card.Body>
              <h4>Use of Information Collected by Vendor</h4>
              <ol type='A'>
                <li>
                  Information collected under this chapter by a vendor may not
                  be used by a person other than the department, the political
                  subdivision, or a vendor as provided by this chapter.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='10'>
            Section 706.011 - Liability of State or Political Subdivision
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='10'>
            <Card.Body>
              <h4>Liability of State or Political Subdivision</h4>
              <ol type='A'>
                <li>
                  An action for damages may not be brought against the state or
                  a political subdivision based on an act or omission under this
                  chapter, including the denial of renewal of a driver's
                  license.{' '}
                </li>
                <li>
                  The state or a political subdivision may not be held liable in
                  damages based on an act or omission under this chapter,
                  including the denial of renewal of a driver's license.{' '}
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey='11'>
            Section 706.012 - Rules
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='11'>
            <Card.Body>
              <h4>Rules</h4>
              <ul>
                <li>
                  The department may adopt rules to implement this chapter.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}

export default SectionSeven
